<template>
	<div class="row mx-0 h-100">
		<div class="col bg-light-warning px-6 py-8 rounded-xl gutter-b d-flex flex-column justify-content-between stat-box">
			<div class="stat text-right">
				<h1>{{ stats.networks || '-' }}</h1>
			</div>
			<div class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
				<div class="mb-2">
					<inline-svg src="/media/svg/icons/Media/Equalizer.svg" />
				</div>
				<span class="text-warning font-weight-bold font-size-h6 mt-2">
					Networks
				</span>
			</div>
		</div>
		<div class="col bg-light-primary px-6 py-8 rounded-xl gutter-b d-flex flex-column justify-content-between mx-3 stat-box">
			<div class="stat text-right">
				<router-link :to="{ name: 'sitelist' }">
					<h1>{{ stats.sites }}</h1>
				</router-link>
			</div>
			<div class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
				<div class="mb-2">
					<router-link :to="{ name: 'sitelist' }">
						<inline-svg src="/media/svg/icons/Map/Marker1.svg" />
					</router-link>
				</div>
				<router-link :to="{ name: 'sitelist' }">
					<span class="sites-label font-weight-bold font-size-h6 mt-2">
						Sites
					</span>
				</router-link>
			</div>
		</div>
		<div class="col bg-light-success px-6 py-8 rounded-xl gutter-b d-flex flex-column justify-content-between mr-3 stat-box">
			<div class="stat text-right">
				<router-link :to="{ name: 'voip' }">
					<h1>{{ stats.voip }}</h1>
				</router-link>
			</div>
			<div class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
				<div class="mb-2">
					<router-link :to="{ name: 'voip' }">
						<inline-svg src="/media/svg/icons/Communication/Call.svg" />
					</router-link>
				</div>
				<router-link :to="{ name: 'voip' }">
					<span id="voip-label" class="font-weight-bold font-size-h6 mt-2">
						VOIP Accounts
					</span>
				</router-link>
			</div>
		</div>
		<div class="col px-6 py-8 rounded-xl gutter-b d-flex flex-column justify-content-between tickets stat-box">
			<div class="stat text-right">
				<router-link :to="{ name: 'tickets' }">
					<h1>{{ stats.tickets }}</h1>
				</router-link>
			</div>
			<div id="ticket-icons" class="svg-icon svg-icon-3x d-block my-2">
				<div class="mb-2">
					<router-link :to="{ name: 'tickets' }">
						<inline-svg src="/media/svg/icons/Shopping/Ticket.svg" />
					</router-link>
				</div>
				<router-link :to="{ name: 'tickets' }">
					<span class="text-ticket font-weight-bold font-size-h6 mt-2">
						Tickets
					</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { SET_READY_STATE } from '@/core/services/store/ready.module';
import swMixin from '@/core/services/mixins/serviceworker.mixin';

export default {
	name: 'DashStats',
	mixins: [swMixin],
	data() {
		return {
			stats: {
				sites: null,
				voip: null,
				tickets: null,
				networks: null,
			},
		};
	},
	created() {
		this.$http.get('accountdashboard').then(resp => {
			this.stats = resp.data.data;
			this.$store.commit(SET_READY_STATE, { component: 'DashStats', ready: true });
		});
		this.setSWListener('accountdashboard', data => {
			this.stats = data.data;
		});
	},
};
</script>

<style lang="scss" scoped>
.stat h1 {
	font-size: 6em;
	opacity: 0.3;
	transition: all 300ms ease-in-out;
}
.stat a {
	color: #3f4254;
	text-decoration: none;
}
.stat-box:hover .stat h1 {
	opacity: 0.5;
}
.tickets {
	background-color: #b7cbf7;
}
::v-deep .tickets div#ticket-icons.svg-icon div svg g path {
	fill: #1f61f1;
}
.text-ticket {
	color: #4278ee;
}
.text-ticket:hover {
	color: #004cf0;
}
#voip-label {
	color: $success;
}
#voip-label:hover {
	color: $success-hover;
}
.sites-label {
	color: $primary;
}
.sites-label:hover {
	color: $primary-hover;
}
.pointer {
	cursor: pointer;
}
</style>
